import { ENV } from "@config/ENV.config";
import currenciesList from "@data/currency-details.json";
import { LocalStorageService } from "@services/utils/localsStorage.service";
import { isObject, isValidDate, nameMapper } from "utils";

import { fileSizeOptions, weekStartDayOptions } from "@data/common.data";
import { Constant } from "./constant";
import { generateDateFormat } from "./date.utils";

const defaultSystemSettings = {
  country_phone_code: "+880",
  token_lifetime: 10,
  timezone: {
    name: "Asia/Dhaka",
    value: "Asia/Dhaka",
  },
  date_format: {
    name: "mm/dd/yyyy",
    value: "mm/dd/yyyy",
  },
  time_format: {
    name: "12 hr",
    value: "12 hr",
  },
  currency_type: {
    name: "BDT",
    value: "BDT",
  },
  currency_precision: {
    name: 1,
    value: 1,
  },
  currency_symbol_placement: {
    name: "Before",
    value: "Before",
  },
  language: {
    name: "en",
    value: "en",
  },
  notification: {
    birthday: true,
    new_joining: false,
  },
  file_types: [
    {
      value: "pdf",
      name: "pdf",
    },
  ],
  file_size: {
    value: "1.3",
  },
  image_types: [
    {
      value: "jpeg",
      name: "jpeg",
    },
    {
      value: "png",
      name: "png",
    },
    {
      value: "jpg",
      name: "jpg",
    },
  ],
  image_size: {
    value: "3.2",
  },
};

// IIFE used to store default system setting in local storage if it's not available
(() => {
  const localData = LocalStorageService.get("systemSettings");
  if (!localData) {
    LocalStorageService.set("systemSettings", defaultSystemSettings);
    window.dispatchEvent(new Event("storage"));
  }
})();

export const systemDate = (date: any) => {
  try {
    const systemSettingsOptions = LocalStorageService.get("systemSettings");
    const { date_format } = systemSettingsOptions;

    if (date && !isValidDate(date)) date = new Date(date);
    if (!isValidDate(date)) return "-";
    return generateDateFormat(date, isObject(date_format) ? date_format?.value : date_format);
  } catch (error) {
    ENV.env === "development" && console.error(error);
    return "-";
  }
};

export const systemTime = (time: any) => {
  try {
    if (!time) return Constant.NO_DATA;
    const systemSettingsOptions = LocalStorageService.get("systemSettings");
    const { timeZone, time_format } = systemSettingsOptions;
    const hour12 = (isObject(time_format) ? time_format.value : time_format) === "24 hr" ? false : true;
    const providedTime = new Date(time);
    const now = new Date();
    let formattedTime: string = "";

    const givenTimeParts = time.split(":");

    if (!isValidDate(providedTime)) {
      // Set time components to the given time
      now.setHours(parseInt(givenTimeParts[0], 10));
      now.setMinutes(parseInt(givenTimeParts[1], 10));
      now.setSeconds(parseInt(givenTimeParts[2], 10));
      // Format the time in the desired format
      formattedTime = now.toLocaleTimeString("en-US", {
        hour12: hour12,
        hour: "numeric",
        minute: "numeric",
        timeZone: isObject(timeZone) ? timeZone.value : timeZone,
      });
    } else {
      formattedTime = providedTime.toLocaleTimeString("en-US", {
        hour12: hour12,
        hour: "numeric",
        minute: "numeric",
        timeZone: isObject(timeZone) ? timeZone.value : timeZone,
      });
    }

    return formattedTime;
  } catch (error) {
    ENV.env === "development" && console.error(error);
    return null;
  }
};

export const systemDateTime = (timeStamp: any) => {
  if (!timeStamp) return;
  return systemDate(timeStamp) + ", " + systemTime(timeStamp);
};

export const systemCurrency = ({ amount = "0" }: { amount: string | number }) => {
  try {
    if (!amount) return "-";
    const systemSettingsOptions = LocalStorageService.get("systemSettings");
    const { currencyType } = systemSettingsOptions;
    const currencyDetails = currenciesList[currencyType];
    if (systemSettingsOptions?.currency_symbol_placement === "before") {
      amount = currencyDetails.symbol_native + " " + amount;
    } else {
      amount = amount + " " + currencyDetails.symbol_native;
    }

    return { currencyDetails: currencyDetails, amount: amount };
  } catch (error) {
    ENV.env === "development" && console.error("Error:", error);
    return "-";
  }
};

export const systemCurrencyPrecision = (amount: number) => {
  try {
    if (!amount) return "-";
    const systemSettingsOptions = LocalStorageService.get("systemSettings");
    const { currency_precision } = systemSettingsOptions;
    return amount.toFixed(currency_precision);
  } catch (error) {
    ENV.env === "development" && console.error("Error:", error);
    return "-";
  }
};

export const systemSettingsMapper = (payload: any) => {
  const {
    contact_person,
    image_size,
    time_format,
    file_size,
    date_format,
    timezone,
    language,
    currency_type,
    currency_precision,
    currency_symbol_placement,
    week_start_day,
  } = payload;

  if (contact_person) payload.contact_person.name = nameMapper(contact_person);

  payload.country_phone_code = "+880";
  if (!isObject(language))
    payload.language = {
      name: language == "ar" ? "عربي" : language == "en" ? "English" : language == "bn" ? "বাংলা" : language,
      value: language,
    };

  if (week_start_day || week_start_day > -1)
    payload.week_start_day = weekStartDayOptions.find((item) => item?.value === week_start_day);

  if (image_size) payload.image_size = fileSizeOptions?.find((item) => item?.value === payload.image_size);
  if (file_size) payload.file_size = fileSizeOptions?.find((item) => item?.value === payload.file_size);

  if (timezone)
    payload.timezone = {
      name: timezone,
      value: timezone,
    };
  if (date_format)
    payload.date_format = {
      name: date_format,
      value: date_format,
    };
  if (time_format)
    payload.time_format = {
      name: time_format,
      value: time_format,
    };
  if (currency_type)
    payload.currency_type = {
      name: currency_type,
      value: currency_type,
    };
  if (currency_precision)
    payload.currency_precision = {
      name: currency_precision,
      value: currency_precision,
    };
  if (currency_symbol_placement)
    payload.currency_symbol_placement = {
      name: currency_symbol_placement,
      value: currency_symbol_placement,
    };
  // if (image_size) payload.image_size = { value: image_size };
  // if (file_size) payload.file_size = { value: file_size };

  if (payload.file_types?.length) {
    payload.file_types = payload?.file_types?.map((item: any) => ({
      value: item,
      name: item,
    }));
  } else {
    payload.file_types = [];
  }

  if (payload.image_types?.length) {
    payload.image_types = payload?.image_types?.map((item: any) => ({
      value: item,
      name: item,
    }));
  } else {
    payload.image_types = [];
  }
  if (payload.attendance_types?.length) {
    payload.attendance_types = payload?.attendance_types?.map((item: any) => ({
      value: item,
      name: item,
    }));
  } else {
    payload.attendance_types = [];
  }
  return payload;
};

export const systemSettingsRequestData = (data: any) => {
  data.file_types = data?.file_types?.map((item: any) => item.value);

  data.image_types = data.image_types?.map((item: any) => item.value);

  data.timezone = data.timezone?.value;

  data.time_format = data.time_format?.value;
  data.date_format = data.date_format?.value;
  data.language = data.language?.value;
  data.image_size = data.image_size?.value;
  data.file_size = data.file_size?.value;
  data.currency_precision = data.currency_precision?.value;
  data.currency_symbol_placement = data.currency_symbol_placement?.value;
  data.currency_type = data.currency_type?.value;

  return data;
};

export const systemSettings = () => {
  return LocalStorageService.get("systemSettings") || defaultSystemSettings;
};
